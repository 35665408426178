import React from "react"
import { graphql } from "gatsby"
import FrontLayout from "../components/FrontLayout"

const Page = ({ data }) => {
  return (
    <FrontLayout>
      <article className="myContainer">
        <h1>{data.wpPage.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
      </article>
    </FrontLayout>
  )
}

export default Page

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`
